.bigSkillsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    overflow: hidden;
    z-index: 1;
}

.skillsTextContainer h2 {
    font-size: 2.4rem;
}

.skillsContainer {
    margin-top: 1vh;
    margin-bottom: 2vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 1vh;
    border-radius: 10px;
    width: 80%;
    gap: 10px;
    padding-top: 4vh;
    padding-bottom: 4vh;
}

.skillsIcons {
    width: auto;
    height: 71px;
    margin-bottom: 0.5vh;
    border-radius: 5px;
}

.skillsIconsContainer {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 6px;
    transition: all 0.5s;
    max-width: 145px;
}

.skillsContainer p {
    font-size: 0.96rem;
}

.skillsIconsContainer:hover {
    transform: scale(1.2);
}

@media screen and (min-width: 1024px) {
    .skillsIcons {
        height: 101px;
        margin-bottom: 1.2vh;
        margin-top: 1.2vh;
    }
    .bigSkillsContainer {
        height: 100%;
        align-items: center;
    }
    .skillsTextContainer {
        width: 100%;
        padding-left: 6vh;
    }
    .skillsContainer {
        margin-top: 2vh;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        padding: 40px;
        gap: 40px;
        width: 85%;
    }
    .skillsContainer p {
        font-size: 1.4rem;
    }
    .skillsIconsContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 124px;
        border-radius: 10px;
        padding: 8px;
    }
    .skillsIconsContainer:hover {
        transform: scale(1.1);
    }
    .skillsTextContainer h2 {
        font-size: 2.8rem;
    }
    .skillsTextContainer {
        margin-top: 20px;
    }
}