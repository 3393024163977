:root #dark {
    --background-color: #111111;
    --primary-color-1: #1C1C1C;
    --primary-color-2: #303339;
    --primary-color-3: #303339c2;
    --secondary-color: #26282c;
    --contrast-color: #ddca1e;
    --shadow: 0px 0px 9px 4px rgba(0, 0, 0, 0.86);
    --secondary-contrast-color: #afa532;
    --text-and-lines: antiquewhite;
    --secondary-text-and-lines: rgb(187, 185, 185);
    --opposite-text-and-lines: black;
    --text-disabled: #cfcfcf;
    --form-bg: #292929;
    --form-text-color: #8f8f8f;
    --submit-button-color: #dbc713;
    --submit-button-shadow: 0px 0px 21px -8px rgba(255, 255, 255, 1);
    --error-color: rgb(230, 0, 0);
}

:root #light {
    --background-color: #C0C5CD;
    --primary-color-1: rgb(238, 238, 238);
    --primary-color-2: #b6bcc7;
    --primary-color-3: rgba(238, 238, 238, 0.801);
    --secondary-color: rgb(238, 238, 238);
    --contrast-color: #3E588F;
    --shadow: 0px 0px 9px 4px rgba(151, 151, 151, 0.86);
    --secondary-contrast-color: #8EA7E9;
    --text-and-lines: rgb(0, 0, 0);
    --secondary-text-and-lines: rgb(46, 37, 37);
    --opposite-text-and-lines: rgb(255, 255, 255);
    --text-disabled: #cfcfcf;
    --form-bg: #292929;
    --form-text-color: #2b2b2b;
    --submit-button-color: #38729b;
    --error-color: rgb(247, 57, 57);
    --submit-button-shadow: 0px 0px 12px 1px rgb(0, 0, 0);
}

body::-webkit-scrollbar {
    width: 8px;
    /* width of the entire scrollbar */
}

body::-webkit-scrollbar-thumb {
    background-color: #303339;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
}

body {
    width: 100%;
    height: 100%;
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#0b090a', endColorstr='#061e1f', GradientType=0);
    overflow: auto;
}

.appContainer {
    display: flex;
    flex-direction: column;
    background: var(--background-color);
    color: var(--text-and-lines);
    align-items: center;
    min-height: 100vh;
    height: 100%;
}

@media screen and (min-width: 1024px) {
    .appContainer {
        flex-direction: row;
    }
}

.bubbles span {
    background: var(--contrast-color);
    border-radius: 1000px;
    box-shadow: 0 0 0 5px var(--secondary-contrast-color), 0 0 50px var(--contrast-color), 0 0 100px var(--contrast-color);
    animation: bubble-animation 150s linear infinite;
    animation-duration: calc(240s/ var(--i));
}

.homeContainer {
    background-color: var(--primary-color-1);
    transition: all 0.5s;
    box-shadow: var(--shadow);
}

.yellow {
    color: var(--contrast-color);
    transition: all 0.5s;
}

.homeContainer hr {
    background-color: var(--text-and-lines);
    transition: all 0.5s;
}

.profileIcons {
    fill: var(--text-and-lines);
    transition: all 0.5s;
}

.bigNavContainer {
    background-color: var(--primary-color-1);
    border-bottom: var(--text-and-lines) solid 1px;
    transition: all 0.5s;
    box-shadow: var(--shadow);
}

.buttonLink:hover {
    box-shadow: var(--shadow);
}

.modeIcons {
    fill: var(--text-and-lines);
    transition: all 0.5s;
}

.navIcons {
    fill: var(--text-and-lines);
    transition: all 0.5s;
}

.navLink {
    background-color: var(--primary-color-2);
    transition: all 0.5s;
}

.navLink p {
    color: var(--text-and-lines);
}

.navLinkAnimated::before {
    background-color: var(--contrast-color);
    transition: all 0.5s;
}

.navLink::after {
    background: var(--secondary-color);
    transition: all 0.5s;
}

.sideNavLink {
    background-color: var(--primary-color-2);
    transition: all 0.5s;
}

.sideNavLink p {
    color: var(--text-and-lines);
    transition: all 0.5s;
}

.sideNavLinkAnimated::before {
    background-color: var(--contrast-color);
    transition: all 0.5s;
}

.sideNavLink::after {
    background: var(--primary-color-2);
    transition: all 0.5s;
}

.navMenu {
    fill: var(--text-and-lines);
    transition: all 0.5s;
}

.navClose {
    fill: var(--text-and-lines);
    transition: all 0.5s;
}

.sideNavContainer {
    background-color: var(--primary-color-1);
    transition: all 0.5s;
}

.switcherText {
    color: var(--form-text-color);
    transition: all 0.5s;
}

.slider {
    background-color: #FEE402;
    transition: all 0.5s;
}

.slider::before {
    background-color: var(--text-and-lines);
    transition: all 0.5s;
}

.slider {
    background-color: var(--contrast-color);
    transition: all 0.5s;
}

.descriptionContainer {
    background-color: var(--primary-color-1);
    transition: all 0.5s;
    box-shadow: var(--shadow);
}

.aboutLinksContainer {
    background-color: var(--primary-color-1);
    transition: all 0.5s;
    box-shadow: var(--shadow);
}

.aboutLinks {
    color: var(--text-and-lines);
    transition: all 0.5s;
}

.aboutLinkInfo a {
    color: var(--contrast-color);
    transition: all 0.5s;
}

.aboutLinkIcon {
    fill: var(--text-and-lines);
    transition: all 0.5s;
}

.aboutInfoContainer {
    background-color: var(--primary-color-1);
    transition: all 0.5s;
    box-shadow: var(--shadow);
}

.aboutLenguageContainer {
    background-color: var(--primary-color-1);
    transition: all 0.5s;
    box-shadow: var(--shadow);
}

.aboutPoint {
    background-color: var(--text-and-lines);
    transition: all 0.5s;
}

.skillsContainer {
    background-color: var(--primary-color-1);
    box-shadow: var(--shadow);
}

.skillsContainer p {
    color: var(--text-and-lines);
    transition: all 0.5s;
}

.skillsIconsContainer:hover {
    background-color: var(--primary-color-2);
    transition: all 0.5s;
    box-shadow: var(--shadow);
}

.projectContainer {
    box-shadow: var(--shadow);
}

.projectInfo {
    background-color: var(--primary-color-1);
    transition: all 0.5s;
    box-shadow: var(--shadow)
}

.mainProjectInfo h3 {
    color: var(--text-and-lines);
    transition: all 0.5s;
}

.githubModalInfo {
    background-color: var(--primary-color-3);
    transition: all 0.5s;
    box-shadow: var(--shadow)
}

.projectInfoIcon {
    fill: var(--text-and-lines);
    transition: all 0.5s;
}

.projectLinkContainer {
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.86);;
}

.projectLinkContainer:hover {
    background-color: var(--contrast-color);
    transition: all 0.5s;
}

.projectLinkContainer:hover p {
    color: var(--opposite-text-and-lines);
    transition: all 0.5s;
}

.projectLinkContainer:hover .projectInfoIcon {
    fill: var(--opposite-text-and-lines);
    transition: all 0.5s;
}

.projectLinkContainer p {
    color: var(--text-and-lines);
    transition: all 0.5s;
}

.contactMeIconsContainer {
    background-color: var(--primary-color-1);
    transition: all 0.5s;
    box-shadow: var(--shadow);
}

.contactMeIconsContainer a {
    color: var(--contrast-color);
    transition: all 0.5s;
}

.contactMeIconsContainer a:hover {
    color: var(--secondary-contrast-color);
    transition: all 0.5s;
}

.contactMeIconsContainer a:hover .contactMeIconSvg {
    fill: var(--secondary-contrast-color);
    transition: all 0.5s;
}

.contactMeIconSvg {
    fill: var(--text-and-lines);
    transition: all 0.5s;
}

.contactForm {
    background-color: var(--primary-color-1);
    transition: all 0.5s;
    box-shadow: var(--shadow);
}

.errorText {
    color: var(--error-color);
    transition: all 0.5s;
}

.contactInput {
    color: var(--form-bg);
    transition: all 0.5s;
}

.inputBox span {
    color: var(--form-text-color);
    transition: all 0.5s;
}

.inputBox i {
    background-color: var(--contrast-color);
    transition: all 0.5s;
}

.borderInput {
    border: var(--form-bg) solid 1px;
    transition: all 0.5s;
}

.borderTextarea {
    border: var(--form-bg) solid 1px;
    transition: all 0.5s;
}

.inputBox input:valid~span,
.inputBox input:focus~span {
    color: var(--contrast-color);
    transition: all 0.5s;
}

.inputBox textarea:valid~span,
.inputBox textarea:focus~span {
    color: var(--contrast-color);
    transition: all 0.5s;
}

.submitButton {
    color: var(--opposite-text-and-lines);
    background-color: var(--submit-button-color);
    box-shadow: var(--submit-button-shadow);
    transition: all 0.5s;
}

.submitButton:disabled {
    background-color: var(--form-bg);
    color: var(--text-disabled);
    transition: all 0.5s;
}

.contactModal {
    background-color: var(--primary-color-2);
    box-shadow: var(--shadow);
}

.closeModalButton {
    background-color: var(--error-color);
    box-shadow: var(--submit-button-shadow);
    color: var(--text-and-lines);
}

@media screen and (min-width: 1024px) {
    .bigNavContainer {
        border-right: var(--text-and-lines) solid 1px;
        transition: all 0.5s;
    }
    .aboutLinks a:hover {
        color: var(--secondary-contrast-color);
        transition: all 0.5s;
    }
}