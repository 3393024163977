.homeWelcomeText h2 {
    font-size: 2.4rem;
}

.bigHomeContainer {
    width: 90%;
    margin-bottom: 5vh;
    padding-bottom: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 1;
}

.homeContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2vh;
    border-radius: 10px;
}

.homeContainer hr {
    width: 100%;
    height: 2px;
    border: none;
    margin-bottom: 2vh;
    margin-top: 2vh;
}

.homeContent {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.dataContent {
    display: flex;
    flex-direction: column;
}

.profilePhotoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.photo {
    width: 80%;
    border-radius: 100%;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.bigProfileIconsContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 2vh;
    margin-bottom: 1vh;
}

.profileIconsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1vh;
    margin-top: 2vh;
    transition: all 0.5s;
}

.profileIconsContainer:hover {
    transform: scale(1.3);
}

.profileIcons {
    width: 6vh;
    height: 6vh;
}

@media screen and (min-width: 1024px) {
    .homeWelcomeText h2 {
        font-size: 3.3rem;
    }
    .homeWelcomeText h3 {
        font-size: 2rem;
    }
    .homeContainer {
        align-items: center;
        justify-content: flex-start;
        padding: 3vh;
        padding-top: 4vh;
        padding-bottom: 4vh;
        width: 80%;
        height: 66%;
        flex-direction: row;
    }
    .homeContent {
        justify-content: center;
    }
    .dataContent {
        margin-left: 5vh;
        height: 100%;
        display: flex;
        justify-content: space-evenly;
    }
    .profilePhotoContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    .photo {
        height: auto;
        min-width: 270px;
        max-width: 270px;
        width: 94%;
        margin-top: 0;
        margin-bottom: 0;
    }
    .bigProfileIconsContainer {
        width: 26%;
        min-width: 190px;
        justify-content: space-between;
    }
    .profileIcons {
        width: 5vh;
        height: 5vh;
    }
    .bigHomeContainer {
        width: 100%;
        margin-bottom: 0;
        height: 76vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (min-width: 1190px) {
    .homeContainer {
        width: 70%;
        height: 60%;
    }
    .dataContent {
        height: 90%;
    }
}