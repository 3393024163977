@import url('https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300;400;500;600;700&display=swap');
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    font-family: 'Signika Negative', sans-serif;
}

button {
    display: inline-block;
    border: none;
    background-color: none;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

textarea {
    resize: none;
}

body {
    width: 100%;
    height: 100%;
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#0b090a', endColorstr='#061e1f', GradientType=0);
    overflow: auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}