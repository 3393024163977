.bigPortfolioContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
}

.portfolioTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.portfolioTextContainer h2 {
    font-size: 2.4rem;
}

.portfolioTextContainer p {
    font-size: 1.2rem;
    margin-top: 6px;
    font-weight: 500;
}

.portfolioContainerPositionBox {
    display: flex;
    justify-content: center;
    margin-top: 3vh;
}

.portfolioContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 3.5vh;
    width: 88%;
    margin-bottom: 3vh;
}

.projectContainer {
    width: 100%;
    height: 42vh;
    min-height: 180px;
    position: relative;
    border-radius: 10px;
    transition: all 0.5s;
    overflow: hidden;
}

.projectImage {
    width: 100%;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
    transition: all 1s;
    height: calc(100% - 116px);
    object-fit: cover;
    object-position: center;
}

.projectContainer:hover .projectImage {
    transform: scale(1.05);
}

.projectTitleContainer {
    display: flex;
    align-items: center;
    width: 100%;
}

.projectInfo {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 116px;
    border-radius: 0 0 8px 8px;
    display: flex;
    align-items: center;
    transition: all 0.5s;
}

.mainProjectInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 84%;
    padding: 0 0px 0 10px;
    width: 95%;
    transition: all 0.5s;
}

.mainProjectInfo h3 {
    font-size: 1.8rem;
    margin-right: 15px;
}

.projectInfoContainer {
    display: flex;
    height: 50px;
    align-items: center;
}

.projectInfoIcon {
    width: 24px;
    height: 24px;
    transition: all 0.5s;
}

.projectLinkContainer:hover {
    transform: scale(1.05);
}

.projectLinkContainer {
    padding: 3px 6px 3px 6px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 11px;
    transition: all 0.5s;
    border-radius: 5px;
}

.projectLinkContainer p {
    margin-right: 5px;
    transition: all 0.5s;
}

.githubModalInfoContainer{
    width: 100%;
    display: flex;
    justify-content: center;
}

.githubModalInfo{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.15rem;
    width: 90%;
    height: 5vh;
    min-height: 70px;
    border-radius: 10px;
    padding: 10px;
    margin-top: 2vh;
}

.githubModalInfo button{
    position: absolute;
    right: 5px;
    top: 5px;
    width: 30px;
    border-radius: 10px;
    background-color: transparent;
    color: var(--text-and-lines);
    font-size: 1.3rem;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: all 0.5s;
}

.githubModalInfo button:hover{
    color: var(--contrast-color);
    transform: scale(1.2);
}

@media screen and (min-width: 811px) {
    .projectContainer {
        width: 45%;
        height: 272px;
    }
}

@media screen and (min-width: 1024px) {
    .bigPortfolioContainer {
        padding: 0 4vh 0 4vh;
        align-items: flex-start;
    }
    .portfolioContainerPositionBox {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 3vh;
    }
    .portfolioContainer {
        flex-wrap: wrap;
        width: 100%;
    }
    .portfolioTextContainer h2 {
        font-size: 2.8rem;
    }
    .portfolioTextContainer p {
        font-size: 1.6rem;
    }
    .portfolioTextContainer {
        margin-top: 20px;
        align-items: flex-start;
    }
    .projectContainer {
        width: 85%;
        min-width: 520px;
        height: 390px;
    }
    .projectInfo {
        height: 130px;
    }
    .mainProjectInfo {
        padding: 0 18px 0 18px;
    }
    .mainProjectInfo h3 {
        font-size: 2rem;
        margin-right: 15px;
    }
}

@media screen and (min-width: 1277px) {
    .projectContainer {
        width: 85%;
        height: 500px;
    }
}

@media screen and (min-width: 1479px) {
    .projectContainer {
        width: 47%;
        height: 390px;
    }
}

@media screen and (min-width: 1600px) {
    .projectContainer {
        height: 420px;
    }
}


@media screen and (min-width: 1700px) {
    .projectContainer {
        height: 442px;
    }
}

@media screen and (min-width: 1800px) {
    .projectContainer {
        height: 466px;
    }
}