.bigContactContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.contactBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.blurBg {
    filter: blur(10px);
    pointer-events: none;
}

.contactTextContainer {
    width: 100%;
}

.contactTextContainer h2 {
    font-size: 2.4rem;
}

.contactContainer {
    margin-top: 2vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 2vh;
    width: 90%;
    margin-bottom: 2vh;
}

.contactMeIconsContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    column-gap: 20px;
    row-gap: 20px;
    padding: 2vh;
    border-radius: 15px;
    margin-bottom: 3vh;
}

.contactMeIcon {
    display: flex;
    align-items: center;
    width: 20%;
    min-width: 250px;
}

.contactMeIconsContainer a {
    cursor: pointer;
}

.contactMeIcon span {
    font-weight: 500;
}

.contactMeIconSvg {
    width: 38px;
    height: 38px;
    margin-right: 12px;
}

.contactForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    padding: 3vh;
    padding-top: 0;
    animation-delay: 0.15s;
}

.inputBox {
    position: relative;
    width: 100%;
    margin-top: 30px;
}

.errorTextContainer {
    display: flex;
    justify-content: flex-end;
}

.errorText {
    margin-top: 5px;
    font-size: 0.85rem;
}

.contactInput {
    position: relative;
    width: 96.1%;
    padding: 20px 8px 10px 8px;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1rem;
    letter-spacing: 0.05rem;
    z-index: 1;
}

.inputBox span {
    position: absolute;
    left: 0;
    font-size: 1em;
    pointer-events: none;
    letter-spacing: 0.05em;
    transition: 0.5s;
}

.inputBox i {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0px;
    border-radius: 4px;
    transition: 0.5s;
    pointer-events: none;
}

.borderInput {
    position: absolute;
    left: -1px;
    bottom: -1px;
    width: 100%;
    height: 80%;
    border-radius: 4px;
}

.borderTextarea {
    position: absolute;
    left: -1px;
    bottom: -1px;
    width: 100%;
    height: 96%;
    border-radius: 4px;
}

.inputBox textarea {
    width: 98.1%;
}

.inputBox input:valid~span,
.inputBox input:focus~span {
    transform: translateY(-56px);
    font-size: 0.75rem;
}

.inputBox textarea:valid~span,
.inputBox textarea:focus~span {
    transform: translateY(-242px);
    font-size: 0.75rem;
}

.inputBox input:valid~i,
.inputBox input:focus~i {
    height: 80%;
}

.inputBox textarea:valid~i,
.inputBox textarea:focus~i {
    height: 96%;
}

.submitButton {
    width: 120px;
    height: 50px;
    border-radius: 10px;
    border: none;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.5s;
}

.loadingContainer {
    width: 120px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadingSubmit {
    width: 40%;
}

.submitButton:hover {
    transform: scale(1.1);
}

.submitButton:disabled {
    box-shadow: none;
    cursor: not-allowed;
}

.submitButton:disabled:hover {
    transform: scale(1);
}

.submitContactContainer {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}

.contactModal {
    width: 66%;
    position: fixed;
    top: 35%;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 4vh;
    border-radius: 15px;
    font-size: 1.05rem;
    text-align: center;
    font-weight: 600;
}

.closeModalButton {
    width: 100px;
    height: 40px;
    border-radius: 8px;
    margin-top: 32px;
    font-size: 1.1rem;
    font-weight: 600;
    transition: all 0.5s;
}

.closeModalButton:hover {
    transform: scale(1.1);
}

@media screen and (min-width: 1024px) {
    .bigContactContainer {
        align-items: flex-start;
    }
    .contactContainer {
        margin-top: 2vh;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 2vh;
    }
    .contactTextContainer h2 {
        font-size: 2.8rem;
    }
    .contactTextContainer {
        margin-top: 20px;
        margin-left: 8vh;
    }
    .inputBox textarea {
        width: 99%;
    }
    .submitContactContainer {
        justify-content: flex-start;
    }
    .submitButton {
        width: 150px;
        height: 60px;
    }
    .loadingContainer {
        width: 150px;
        height: 60px;
    }
    .loadingSubmit {
        width: 34%;
    }
    .contactModal {
        width: 320px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@media screen and (min-width: 500px) {
    .contactInput {
        width: 100%;
    }
}

@media screen and (max-width: 1024px) {
    .contactContainer {
        align-items: center;
        padding: 0;
        width: 90%;
    }
    .contactBox {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .contactTextContainer {
        display: flex;
        justify-content: center;
    }
    .contactMeIconsContainer {
        width: 90%;
        padding: 2vh;
        margin-bottom: 14px;
        margin-top: 15px;
        justify-content: flex-start;
    }
    .contactMeIcon {
        margin-left: 5px;
    }
    .contactForm {
        width: 90%;
        padding: 2vh;
        padding-top: 0;
    }
    .contactTextContainer {
        width: 87%;
        margin-bottom: 10px;
    }
}