.navBox {
    width: 100%;
    margin-bottom: 82px;
}

.bigNavContainer {
    width: 100%;
    height: 60px;
    position: fixed;
    top: -1px;
    display: flex;
    align-items: center;
    margin-bottom: 5vh;
    z-index: 2;
}

.logo {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 5px;
}

.logo img {
    height: 80%;
    border-radius: 5px;
}

.navContainer {
    display: none;
}

.locationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.locationContainer h2 {
    font-size: 2rem;
    font-weight: 600;
}

.navIcons {
    z-index: 1;
    width: 20px;
    height: 20px;
    margin-right: 1vh;
}

.navLink {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
}

.navLinkAnimated {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
}

.buttonLink {
    width: 128px;
    height: 50px;
    margin-right: 20px;
    transition: all 0.5s;
}

.buttonLink:hover {
    transform: scale(1.1);
    border-radius: 10px;
}

.navLink p {
    font-size: 1rem;
    font-weight: 500;
    z-index: 1;
}

.navLinkAnimated::before {
    content: "";
    width: 100%;
    height: 8px;
    position: absolute;
    animation: spinBorder 6s linear infinite;
}

.navLink::after {
    content: "";
    position: absolute;
    inset: 2px;
    border-radius: 8px;
}

.link1 {
    animation-delay: 0.3s;
}

.link2 {
    animation-delay: 0.6s;
}

.link3 {
    animation-delay: 0.9s;
}

.link4 {
    animation-delay: 1.2s;
}

.sideNavLink {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 288px;
    height: 54px;
    border-radius: 8px;
    transition: all 0.5s;
    position: relative;
    overflow: hidden;
}

.sideNavLinkAnimated {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 288px;
    height: 54px;
    border-radius: 8px;
    transition: all 0.5s;
    position: relative;
    overflow: hidden;
}

.sideNavLink p {
    font-size: 1rem;
    font-weight: 500;
    z-index: 1;
}

.sideNavLinkAnimated::before {
    content: "";
    width: 100%;
    height: 8px;
    position: absolute;
    animation: spinBorder 4s linear infinite;
}

.sideNavLink::after {
    content: "";
    position: absolute;
    inset: 3px;
    border-radius: 8px;
}

@keyframes spinBorder {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.bigSideNavContainer {
    display: flex;
    justify-content: end;
    position: fixed;
    z-index: 4;
    top: 0;
    width: 100%;
    overflow: hidden;
}

.smallSideNav {
    height: 60px;
}

.bigSideNav {
    height: 100%;
}

.navMenu {
    cursor: pointer;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 14px;
    right: 14px;
}

.navClose {
    cursor: pointer;
    width: 42px;
    height: 42px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.sideNavContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 0px;
    width: 100%;
    height: 100%;
    padding: 10px;
    --animate-duration: 0.5s;
    padding-top: 25px;
}

.buttonsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.switchersBox {
    display: flex;
    gap: 10px;
    width: 80%;
    justify-content: space-evenly;
    position: relative;
    height: 120px;
    align-items: center;
    margin-top: 20px;
    flex-direction: column;
    animation-delay: 1.5s;
}

.switchersBoxOnPhone {
    display: flex;
    gap: 10px;
    width: 98%;
    justify-content: center;
    position: relative;
    height: 70px;
    align-items: center;
    margin-top: 15px;
    animation-delay: 1.5s;
}

.switcherContainer {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.modeIcons {
    z-index: 1;
    width: 26px;
    height: 26px;
}

.littleMarginLeft {
    margin-left: 4px;
}

.switcherText {
    font-size: 1.1rem;
    font-weight: 400;
}

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 20px;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.4s;
    border-radius: 34px;
}

.slider::before {
    position: absolute;
    content: "";
    height: 28px;
    width: 28px;
    left: 0px;
    bottom: -4px;
    transition: 0.4s;
    border-radius: 50px;
}

.english .slider::before {
    transform: translateX(22px);
}

.light .slider::before {
    transform: translateX(22px);
}

@media screen and (min-width: 1024px) {
    .navContainer {
        width: 100%;
        height: 56%;
        min-height: 320px;
        gap: 18px;
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-left: 0;
    }
    .buttonLink {
        margin-right: 0;
        width: 80%;
    }
    .navBox {
        width: 330px;
        min-width: 330px;
    }
    .bigNavContainer {
        width: 330px;
        min-width: 330px;
        height: 100%;
        border-bottom: none;
        margin-bottom: 0;
        flex-direction: column-reverse;
        justify-content: flex-end;
        position: fixed;
        top: 0;
    }
    .logo {
        height: 30%;
        width: 100%;
        margin-left: 0;
        position: relative;
    }
    .logo img {
        height: auto;
        width: 47%;
    }
    .navMenu {
        display: none;
    }
    .bigSideNavContainer {
        display: none;
    }
}