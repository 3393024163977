.bigAboutContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
}

.aboutTextContainer h2 {
    font-size: 2.4rem;
}

.aboutContainer {
    margin-top: 2vh;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 88%;
}

.descriptionContainer {
    padding: 2.8vh;
    width: 88%;
    border-radius: 15px;
}

.descriptionContainer h5 {
    font-size: 1.6rem;
    margin-bottom: 16px;
}

.descriptionContainer p {
    font-size: 1.14rem;
    font-weight: 500;
    line-height: 27px;
}

.aboutLinksContainer {
    width: 88%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 2.8vh;
    border-radius: 15px;
    animation-delay: 0.28s;
}

.aboutLinks {
    width: 95%;
    max-width: 282px;
    margin: 8px 7.5px 8px 7.5px;
    display: flex;
    align-items: flex-end;
}

.aboutLinkInfo {
    display: flex;
    align-items: flex-end;
    height: 34px;
    overflow: hidden;
}

.aboutLinkInfo h3 {
    font-size: 1.24rem;
    font-weight: 500;
    margin-right: 5px;
}

.aboutLinkInfo p {
    font-size: 1rem;
    padding-bottom: 1px;
    height: min-content;
    text-align: center;
}

.aboutLinkInfo a {
    font-size: 1.1rem;
    transition: all 0.22s;
}

.aboutLinkIcon {
    width: 25px;
    height: 25px;
    margin-right: 6px;
}

.educationAndExperienceContainer {
    width: 100%;
}

.bigAboutInfoContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aboutInfoContainer {
    border-radius: 15px;
    margin-bottom: 20px;
    padding: 2.8vh;
    width: 88%;
    animation-delay: 0.5s;
}

.aboutInfoContainer h5 {
    font-size: 1.8rem;
    margin-bottom: 12px;
}

.aboutLenguageContainer {
    border-radius: 15px;
    margin-bottom: 20px;
    padding: 2.8vh;
    width: 88%;
    animation-delay: 0.65s;
}

.aboutLenguageContainer h5 {
    font-size: 1.8rem;
    margin-bottom: 12px;
}

.aboutLineAndInfo {
    display: flex;
}

.aboutInfoLine {
    margin: 15px 16px 24px 5px;
}

.aboutPoint {
    border-radius: 100px;
    width: 14px;
    height: 14px;
    position: absolute;
    left: -24px;
    top: 10px;
}

.aboutInfo {
    margin-bottom: 22px;
    position: relative;
}

.infoTitle {
    margin-bottom: 10px;
}

.infoTitleName {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 3px;
}

.infoTitleDate {
    font-size: 0.8rem;
    font-weight: 600;
}

.aboutInfo p {
    font-size: 1.03rem;
    font-weight: 400;
    line-height: 24px;
}

@media screen and (min-width: 1024px) {
    .bigAboutContainer {
        align-items: flex-start;
    }
    .aboutTextContainer {
        margin-left: 4vh;
        margin-top: 20px;
    }
    .aboutTextContainer h2 {
        font-size: 2.8rem;
    }
    .aboutContainer {
        width: 100%;
    }
    .descriptionContainer {
        width: 86%;
    }
    .aboutLinksContainer {
        width: 86%;
    }
    .aboutLinks {
        margin-right: 30px;
        max-width: 300px;
    }
    .aboutLinkIcon {
        width: 35px;
        height: 35px;
    }
    .educationAndExperienceContainer {
        width: 100%;
    }
    .bigAboutInfoContainer {
        width: 100%;
        height: 100%;
    }
    .aboutInfoContainer {
        width: 86%;
        padding: 2.8vh;
    }
    .aboutLenguageContainer {
        width: 86%;
        padding: 2.8vh;
    }
}

@media screen and (min-width: 1300px) {
    .aboutInfoContainer {
        width: 94.5%;
    }
    .aboutLenguageContainer {
        margin-top: 0vh;
    }
    .bigAboutInfoContainer {
        justify-content: space-around;
    }
    .educationAndExperienceContainer {
        width: 91%;
    }
}

@media screen and (min-width: 1406px) {}

@media screen and (min-width: 1504px) {
    .bigAboutInfoContainer {
        justify-content: space-evenly;
    }
    .aboutLinksContainer {
        justify-content: flex-start;
    }
}

@media screen and (min-width: 1800px) {
    .aboutLinks {
        margin-right: 100px;
    }
}